import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadToAnExistingFolderRequest } from '../uploadtoanexistingfolderrequest';
import { Router } from '@angular/router';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService, HttpError } from '@prekog/pw-common';
import { UploadToAnExistingFolderResponse } from '../uploadtoanexistingfolderresponse';
import { GetProductResponse } from './getproductresponse';
import { GetProductRequest } from './getproductrequest';
import { UpdateProductRequest } from './updateproductrequest';
import { UpdateProductResponse } from './updateproductresponse';
import { UploadFileResponse } from '../addproduct/uploadfileresponse';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError, take, map } from 'rxjs';
import { SetPreorderPublishedRequest } from './setpreorderpublishedrequest';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmsetpreorderpublishedpopupComponent } from './confirmsetpreorderpublishedpopup/confirmsetpreorderpublishedpopup.component';
import { Material } from '../material';

@Component({
    selector: 'app-updateproduct',
    templateUrl: './updateproduct.component.html',
    styleUrls: ['./updateproduct.component.scss']
})
export class UpdateproductComponent implements OnInit {
    storeBaseUrl = '';

    storeExistingProductsURI = '/store/get-products';

    modifyProductsURI = '/admin/updateproduct';

    setPublishedUrl = '/admin/setpublished';

    uploadFileToAnExistingFolderUrl = '/admin/updatefile';

    getProductUrl = '/admin/getproduct';

    uploadFileUrl = '/admin/uploadFile';

    productForm?: FormGroup;

    htmlForm?: FormGroup;

    updateProductRequest?: UpdateProductRequest = {};

    product: Material = {};

    productToUpdate: Material = {};

    isModifyProductVisible = false;

    setPreorderPublishedRequest: SetPreorderPublishedRequest = {};

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    @ViewChild('fileBlurbInput') fileBlurbInput!: ElementRef;
    fileBlurb: File | null = null;

    @ViewChild('fileBookExtractInput') fileBookExtractInput!: ElementRef;
    fileBookExtract: File | null = null;

    @ViewChild('isAvailableInput') isAvailableInput!: ElementRef;
    isAvailable = false;
    @ViewChild('hasPreorderInput') hasPreorderInput!: ElementRef;
    hasPreorder = false;
    @ViewChild('webshopPriceActiveInput') webshopPriceActiveInput!: ElementRef;
    webshopPriceActive = false;
    @ViewChild('discountPriceActiveInput') discountPriceActiveInput!: ElementRef;
    discountPriceActive = false;

    @ViewChild('discountPriceInput') discountPriceInput!: ElementRef;
    discountPrice: number | null = null;
    @ViewChild('translatorInput') translatorInput!: ElementRef;
    translator: string = '';
    @ViewChild('packagePriceInput') packagePriceInput!: ElementRef;
    packagePrice: number | null = null;
    @ViewChild('webshopPriceInput') webshopPriceInput!: ElementRef;
    webshopPrice: number | null = null;
    @ViewChild('illustratorInput') illustratorInput!: ElementRef;
    illustrator: string = '';
    @ViewChild('subtitleInput') subtitleInput!: ElementRef;
    subtitle: string = '';
    @ViewChild('preorderPriceInput') preorderPriceInput!: ElementRef;
    preorderPrice: number | null = null;

    @ViewChild('discountLimitedUntilInput') discountLimitedUntilInput!: ElementRef;
    discountLimitedUntil = false;

    fileManagerUrl = '';

    uploadToAnExistingFolderRequest?: UploadToAnExistingFolderRequest = {};

    getProductRequest: GetProductRequest = {};

    uploadFileResponse: UploadFileResponse = {};

    downloadUri = '';

    url = '';

    txt = '';

    isUrlModified = false;

    isTxtModified = false;

    bookExtractUrl = '';

    updateCoverFile = false;

    updateBlurbFile = false;

    updateBookExtractFile = false;

    maxFileSize = 5000000;

    available = '';

    isExtraSmall: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    daysBetween = 0;

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.fileManagerUrl = _fileManagerUrl;
        this.createProductForm();
        this.createHtmlForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }
    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            const today = new Date();
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            const routerLink = lastSegmentOfUrl as string;
            this.getProductRequest = {
                routerLink: routerLink
            };
            this._apiRequestService
                .sendRequest<GetProductRequest, GetProductResponse>(
                    this.storeBaseUrl,
                    true,
                    this.getProductRequest,
                    true,
                    this.getProductUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this.product = response.product!;

                    if (this.product.discountLimitedUntil !== null) {
                        this.calculateDaysBetweenIfDiscountLimitedUntil(this.product.discountLimitedUntil!);
                    }

                    this.isAvailable = this.product.isAvailable as boolean;

                    this.hasPreorder = this.product.hasPreorder as boolean;

                    this.webshopPriceActive = this.product.webshopPriceActive as boolean;

                    this.webshopPrice = this.product.webshopPrice as number;

                    this.discountPriceActive = this.product.discountPriceActive as boolean;

                    this.discountPrice = this.product.discountPrice as number;

                    this.translator = this.product.translator as string;

                    this.packagePrice = this.product.packagePrice as number;

                    this.webshopPrice = this.webshopPrice as number;

                    this.illustrator = this.product.illustrator as string;

                    this.subtitle = this.product.subtitle as string;

                    this.preorderPrice = this.product.preorderPrice as number;

                    if (this.product.discountLimitedUntil !== null) {
                        this.discountLimitedUntil = true;
                    }
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    calculateDaysBetweenIfDiscountLimitedUntil(discountLimitedUntil: Date) {
        // Create Date objects representing the two dates
        const date1: Date = new Date();
        const date2: Date = new Date(discountLimitedUntil);
        console.log(date1)
        console.log(date2)

        const differenceInMs: number =
            Math.abs(date2.getTime() - date1.getTime());

        const millisecondsInDay: number = 1000 * 60 * 60 * 24;

        this.daysBetween =
            Math.floor(differenceInMs / millisecondsInDay);
    }

    uploadFileBookExtract(product: Material) {
        //TODO call file manager to get downloadUri
        const fileBookExtractObservable = this._apiRequestService
            .uploadFile(this.fileBookExtract!, true, true, this.fileManagerUrl, this.uploadFileUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this.uploadFileResponse = response as UploadFileResponse;
                this.downloadUri = this.uploadFileResponse.downloadUri as string;
                if (this.uploadFileResponse.downloadUri) {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        'Sikeres fájl feltöltés ' + this.uploadFileResponse.fileName!,
                        MAT_ICON.success
                    );
                    this.dismissError();
                }

                this.bookExtractUrl = this.downloadUri;

                this.updateBookExtractFile = false;

                this.updateProduct();
            });
    }
    createProductForm() {
        this.productForm = this._fb.group({
            content: ['', [Validators.required]],
            author: ['', [Validators.required]],
            title: ['', [Validators.required]],
            originalPrice: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
            isbn: ['', [Validators.required]],
            available: ['', [Validators.required]],
            bond: ['', [Validators.required]],
            dimension: ['', [Validators.required]],
            quantityofsupply: ['', [Validators.required]]
        });
    }

    collectProductData() {
        this.productToUpdate = {
            id: this.product.id,
            content: this.productForm?.controls['content'].value as string | undefined,
            author: this.productForm?.controls['author'].value as string | undefined,
            title: this.productForm?.controls['title'].value as string | undefined,
            originalPrice: this.productForm?.controls['originalPrice'].value as number | undefined,
            isbn: this.productForm?.controls['isbn'].value as number | undefined,
            available: this.product.available,
            bond: this.productForm?.controls['bond'].value as string | undefined,
            dimension: this.productForm?.controls['dimension'].value as string | undefined,
            quantityOfSupply: this.productForm?.controls['quantityofsupply'].value as number | undefined,

            isAvailable: this.isAvailable,
            webshopPriceActive: this.webshopPriceActive,
            discountPriceActive: this.discountPriceActive,
            discountPrice: this.discountPrice as number,
            hasPreorder: this.hasPreorder,
            translator: this.translator,
            packagePrice: this.packagePrice as number,
            webshopPrice: this.webshopPrice as number,
            illustrator: this.illustrator,
            subtitle: this.subtitle,
            preorderPrice: this.preorderPrice as number,
            txt: this.htmlForm?.controls['html'].value as string | undefined
        };

        if (this.url) {
            this.productToUpdate.url = this.url;
        } else if (!this.url) {
            this.productToUpdate.url = this.product.url;
        }
        if (this.bookExtractUrl) {
            this.productToUpdate.bookExtractUrl = this.bookExtractUrl;
        } else if (!this.bookExtractUrl) {
            this.productToUpdate.bookExtractUrl = this.product.bookExtractUrl;
        }
    }

    updateProductData(product: Material) {
        if (!this.productForm?.valid) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.success,
                'A *-al jelölt mezők kitöltése kötelező!"' as string,
                MAT_ICON.success
            );
            this.dismissError();
        } else {
            if (this.updateCoverFile) {
                this.updateCover(product.url!);
            }

            this.updateProduct();

            if (this.updateBookExtractFile) {
                if (product.bookExtractUrl) {
                    this.updateBookExtract(product.bookExtractUrl!);
                } else {
                    this.uploadFileBookExtract(product);
                }
            }
        }
    }

    updateProduct() {
        if (this.fileCover?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        }
        else if (this.fileCover === null || this.fileCover?.size! < this.maxFileSize) {
            this.collectProductData();
            this.updateProductRequest = {
                product: this.productToUpdate,
                discountLimitedUntil: this.discountLimitedUntil
            };

            this._apiRequestService
                .sendRequest<UpdateProductRequest, UpdateProductResponse>(
                    this.storeBaseUrl,
                    true,
                    this.updateProductRequest,
                    true,
                    this.modifyProductsURI
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage as string, MAT_ICON.success);
                    this.dismissError();
                });
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    onChangeCover(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'png') {
            this.updateCoverFile = true;
        }
        else {
            this.updateCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    onChangeBookExtract(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileBookExtract = target.files![0];
        if (this.fileBookExtract.name.split('.').pop() === 'pdf') {
            this.updateBookExtractFile = true;
        }
        else {
            this.updateBookExtractFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".pdf" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    updateBookExtract(downloadUrl: string) {
        if (this.fileBookExtract?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        } else {
            this.uploadToAnExistingFolderRequest = {
                downloadUri: downloadUrl
            };

            const folderName = downloadUrl.split('/').pop() as string;

            this._apiRequestService
                .updateFile(
                    this.fileBookExtract!,
                    folderName,
                    true,
                    true,
                    this.fileManagerUrl,
                    this.uploadFileToAnExistingFolderUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    const responseMessageObject = response as UploadToAnExistingFolderResponse;
                    if (responseMessageObject.downloadUri) {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            'Sikeres fájl feltöltés ',
                            MAT_ICON.success
                        );
                        this.dismissError();
                    }
                    this.bookExtractUrl = this.downloadUri;

                    this.updateBookExtractFile = false;
                });

        }
    }

    //TODO fix '/admin/uploadtoexistingfolder' call, content-type is wrong
    updateCover(downloadUrl: string) {
        if (this.fileCover?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        } else {
            this.uploadToAnExistingFolderRequest = {
                downloadUri: downloadUrl
            };

            const folderName = downloadUrl.split('/').pop() as string;

            this._apiRequestService
                .updateFile(
                    this.fileCover!,
                    folderName,
                    true,
                    true,
                    this.fileManagerUrl,
                    this.uploadFileToAnExistingFolderUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    const responseMessageObject = response as UploadToAnExistingFolderResponse;
                    //TODO separate success and error message
                    if (responseMessageObject.downloadUri) {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            'Sikeres fájl feltöltés ',
                            MAT_ICON.success
                        );
                        this.dismissError();
                    }
                    this.url = this.downloadUri;

                    this.updateCoverFile = false;
                });
        }
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.updateCoverFile = false;
    }

    clearSelectedBlurbFile() {
        this.fileBlurbInput.nativeElement.value = null;
        this.updateBlurbFile = false;
    }

    clearSelectedBookExtractFile() {
        this.fileBookExtractInput.nativeElement.value = null;
        this.updateBookExtractFile = false;
    }

    setPreorderTrue() {
        if (this.product.isAvailable) {
            this.product.isAvailable = false;
        }
        this.product.discountPriceActive = false;
        this.product.webshopPriceActive = false;
    }

    setDiscountPriceActiveTrue() {
        this.product.webshopPriceActive = false;
        this.product.hasPreorder = false;
    }

    setWebshopPriceActiveTrue() {
        this.product.discountPriceActive = false;
        this.product.hasPreorder = false;
    }

    onChangeDiscountPrice() {
        this.discountPrice = this.discountPriceInput.nativeElement.value;
    }

    onChangeDiscountLimitedUntil() {
        this.discountLimitedUntil = !this.discountLimitedUntil;
    }

    onChangeWebshopPrice() {
        this.webshopPrice = this.webshopPriceInput.nativeElement.value;
    }

    onChangePreorderPrice() {
        this.preorderPrice = this.preorderPriceInput.nativeElement.value;
    }

    onChangeTranslator() {
        this.translator = this.translatorInput.nativeElement.value;
    }

    onChangeIllustrator() {
        this.illustrator = this.illustratorInput.nativeElement.value;
    }

    onChangeSubtitle() {
        this.subtitle = this.subtitleInput.nativeElement.value;
    }

    onChangePackagePrice() {
        this.packagePrice = this.packagePriceInput.nativeElement.value;
    }

    onChangeIsAvailable() {
        this.isAvailable = !this.isAvailable;
    }

    onChangeDiscountPriceActive() {
        this.discountPriceActive = !this.discountPriceActive;
        if (this.discountPriceActive) {
            this.webshopPriceActive = false;
            this.hasPreorder = false;
        }
    }

    onChangePreorderPriceActive() {
        this.hasPreorder = !this.hasPreorder;
        if (this.hasPreorder) {
            this.isAvailable = false;
            this.webshopPriceActive = false;
            this.discountPriceActive = false;
        }
    }

    onChangeWebshopPriceActive() {
        this.webshopPriceActive = !this.webshopPriceActive;
        if (this.webshopPriceActive) {
            this.discountPriceActive = false;
            this.hasPreorder = false;
        }
    }

    onChangeAvailable(event: Event) {
        const target = event.target as HTMLInputElement;
        const available = new Date(target.value);
        this.product.available = available;

        console.log(target.value);
    }

    openPdf(bookExtractUrl: string) {
        window.open(bookExtractUrl);
    }

    setPreorderPublished() {
        const popupDialog = this._matDialog.open(ConfirmsetpreorderpublishedpopupComponent, {
            data: this.product,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }
}
