export class Material {
    id?: number;
    routerLink?: string;
    url?: string;
    textData?: string;
    title?: string;
    txt?: string;
    author?: string;
    originalPrice?:number;
    oldPrice?:number;
    isAvailable?: boolean;
    isbn?: number;
    available?: Date;
    content?: string;
    bond?: string;
    quantity?: number;
    quantityOfSupply?: number;
    webshopPriceActive?:boolean;
    discountPriceActive?:boolean;
    discountPrice?:number;
    webshopPrice?:number;
    packagePrice?:number;
    hasPreorder?:boolean;
    preOrderTxt?: string;
    preOrderData?: string;
    dimension?:string;
    translator?:string;
    bookExtractUrl?:string;
    packageName?:string;
    firstElementOfPackage?:boolean;
    serieId?:number;
    serieNumber?:number;
    illustrator?:string;
    subtitle?:string;
    orderNumber?:number;
    preorderPrice?:number;
    typeOfPrice?:string;
    discountLimitedUntil?:Date;

}
export class MaterialElement {
    author?: string;

    available?: Date;

    case?: string;

    content?: string;

    id?: number;

    isAvailable?: boolean;

    isbn?: number;

    price?: number;

    quantity?: number;

    quantityOfSupply?: number;

    routerLink?: string;

    textData?: string;

    title?: string;

    txt?: string;

    url?: string;

    translator?:string;

    discountLimitedUntil?:Date;
}
